import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AuctionCard from "../components/AuctionCard"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Земельные участки. Архив аукционов." />
    <h2 className="mt-4">Прошедшие аукционы земельных участков</h2>
    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
      {data.allStrapiAuction &&
        data.allStrapiAuction.nodes.map(item => (
          <AuctionCard key={item.id} item={item} />
        ))}
    </div>
  </Layout>
)

export const query = graphql`
  query ArchiveQuery($startDate: Date) {
    allStrapiAuction(
      filter: { Date: { lt: $startDate } }
      sort: { fields: Date, order: DESC }
    ) {
      totalCount
      nodes {
        id
        Title
        Date
        Region {
          id
          Name
        }
      }
    }
  }
`

export default IndexPage
